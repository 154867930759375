import '@/styles/global.scss'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import '@/styles/moveable.css'
import '@/component/RichTextEditor/editor.css'

import { SidebarDrawerProvider } from '@/Context/SidebarDrawerContext'
import AlertCookies from '@/component/AlertCookies'
import { queryClient } from '@/services/queryClient'
import { theme } from '@/styles/theme'
import { ChakraProvider } from '@chakra-ui/provider'
import { createStandaloneToast } from '@chakra-ui/toast'
import { Hydrate, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import type { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import Script from 'next/script'
import NextNProgress from 'nextjs-progressbar'
import { useEffect, useRef } from 'react'
import * as fbq from '../services/fpixel'
import { SocketProvider } from '@/Context/Socket'
import { SessionProvider } from 'next-auth/react'
import { ImageGenerateProvider } from '@/Context/ImageStylesPromptContext'

const { ToastContainer } = createStandaloneToast()

export default function App({
  Component,
  pageProps: { session, ...pageProps }
}: AppProps) {
  const router = useRouter()
  const queryRef = useRef(queryClient)

  useEffect(() => {
    fbq.pageview('PageView')

    const handleRouteChange = () => {
      fbq.pageview()
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return (
    <>
      {/*
      <Script
        strategy="beforeInteractive"
        id="script-gptmax"
        data-token="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoxNzE2LCJrbm93bGVkZ2VfYmFzZV9pZCI6MjczOH0.oaEiB9wu14FLK9sPBWoEIZbDFl0xV6XyrQ1k1gXu8PE"
        src="https://d1nfa9z59crrh.cloudfront.net/scripts-files/test-index.umd.js?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoyMDY3LCJrbm93bGVkZ2VfYmFzZV9pZCI6MzkxNH0.iKZwjg7LEDpeUn1wCainEq5DgDdojI6bivuNnO2RRQk"
      />
      */}
      <Script
        id="fb-pixel"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', ${fbq.FB_PIXEL_ID});
          `
        }}
      />
      <Script
        src="https://www.googletagmanager.com/gtag/js?id=G-PE6WP5LHJ3"
        strategy="afterInteractive"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-PE6WP5LHJ3');
        `}
        {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-W84NL5D4');
          `}
      </Script>

      {/* <Script
        strategy="beforeInteractive"
        id="script-gptmax"
        src="https://d1nfa9z59crrh.cloudfront.net/scripts-files/index.umd.js?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoyNCwia25vd2xlZGdlX2Jhc2VfaWQiOjg3fQ.WCluL6T0JDm5EU0YhD0mGBkbrcLGQcod5oFUhA1MTpo"
      />  */}

      <SessionProvider
        session={session}
        // basePath="/dashboard"
        // refetchInterval={10}
        // refetchOnWindowFocus={true}
      >
        <QueryClientProvider client={queryRef.current}>
          <SocketProvider>
            <ToastContainer />
            <ChakraProvider theme={theme}>
              <NextNProgress
                nonce={''}
                color={'#1F9F83'}
                startPosition={0.3}
                stopDelayMs={200}
                height={4}
                showOnShallow={false}
                options={{ easing: 'ease', speed: 500 }}
              />
              <SidebarDrawerProvider>
                <ImageGenerateProvider>
                  <Hydrate state={pageProps.dehydratedState}>
                    <Component {...pageProps} />
                  </Hydrate>
                </ImageGenerateProvider>
              </SidebarDrawerProvider>
              {/* <AlertMessage /> */}
              <AlertCookies />
            </ChakraProvider>
            <ReactQueryDevtools />
          </SocketProvider>
        </QueryClientProvider>
      </SessionProvider>
    </>
  )
}
